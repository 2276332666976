import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
  finalUsers: [],
  searchTerm: "",
  searchLocation: "",
  userMaps: true,
  isLoading: false,
  connectionModal: false,
  openJobModal: false,
  categories: [],
  userAdTypes: [],
  selectedPeopleCategory: null,
};

const peopleInCom = createSlice({
  name: "peopleInCom",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setFinalUsers: (state, action) => {
      state.finalUsers = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setSearchLocation: (state, action) => {
      state.searchLocation = action.payload;
    },
    setUserAdType: (state, action) => {
      state.userAdTypes = action.payload;
    },
    setUserMaps: (state, action) => {
      state.userMaps = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setConnectionModal: (state, action) => {
      state.connectionModal = action.payload;
    },
    setOpenJobModal: (state, action) => {
      state.openJobModal = action.payload;
    },

    setCategories: (state, action) => {
      state.categories = action.payload;
    },

    setSelectedPeopleCategory: (state, action) => {
      state.selectedPeopleCategory = action.payload;
    },
  },
});

export const {
  setAllUsers,
  setSearchTerm,
  setSearchLocation,
  setUserAdType,
  setFinalUsers,
  setUserMaps,
  setIsLoading,
  setConnectionModal,
  setOpenJobModal,
  setCategories,
  setSelectedPeopleCategory,
} = peopleInCom.actions;
export default peopleInCom.reducer;
