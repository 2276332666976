import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
// import SearchByLocation from "./SearchByLocation";
import Helmet from "../../components/Helmet/Helmet";
import SearchByUsers from "./SearchByUsers";
import SearchByLocation from "./SearchByLocation";
import "./PeopleInMyCommunity.css";
import MoreFilters from "./MoreFilters";
import { fetchAllUsers, fetchCategories } from "../../components/Header/Data";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllUsers,
  setCategories,
  setFinalUsers,
  setIsLoading,
  setUserAdType,
} from "../../redux/slices/PeopleInComSlice";
import UsersContainer from "./UsersContainer";
import SearchByType from "./SearchByType";
import { usersAdTypesApi } from "../../components/Header/Data3";

const PeopleInMyCom = () => {
  const dispatch = useDispatch();
  const {
    allUsers,
    searchTerm,
    searchLocation,
    userAdTypes,
    selectedPeopleCategory,
    finalUsers,
    isLoading,
  } = useSelector((state) => state.peopleInCom);
  const fetchUsers = async () => {
    dispatch(setIsLoading(true));
    const users = await fetchAllUsers();
    const cat = await fetchCategories();
    const userTypes = await usersAdTypesApi();
    dispatch(setUserAdType(userTypes));
    dispatch(setCategories(cat));
    dispatch(setAllUsers(users));
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  React.useEffect(() => {
    let initialdata = allUsers;
    const apply = () => {
      if (searchTerm) {
        initialdata = allUsers.filter(
          (user) =>
            (user.FirstName &&
              user.FirstName.toLowerCase().includes(
                searchTerm.toLowerCase()
              )) ||
            (user.LastName &&
              user.LastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.UserName &&
              user.UserName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      }
      if (searchLocation) {
        initialdata = initialdata.filter(
          (user) =>
            user.Location &&
            user.Location.toLowerCase().includes(searchLocation.toLowerCase())
        );
      }
      if (selectedPeopleCategory) {
        const catName = selectedPeopleCategory.Name.toLowerCase();
        // Find userAdTypes that contain the selected category
        const filteredAdTypes = userAdTypes.filter((adType) =>
          adType.categoryName.toLowerCase().includes(catName)
        );
        // Extract the userIds of the filtered ad types
        const userIds = filteredAdTypes.map((adType) => adType.userId);
        // Filter users whose userId is in the filtered userIds list
        initialdata = initialdata.filter((user) => userIds.includes(user.Id));
      }
    };
    apply();
    dispatch(setFinalUsers(initialdata));
  }, [allUsers, searchTerm, searchLocation, selectedPeopleCategory]);
  return (
    <Helmet title="my-community">
      <div className="wc-people-community">
        <Row className="mb-2">
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <SearchByUsers />
          </Col>
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <SearchByLocation />
          </Col>
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <SearchByType />
          </Col>
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <MoreFilters />
          </Col>
        </Row>
        <div>
          <UsersContainer />
        </div>
      </div>
    </Helmet>
  );
};

export default PeopleInMyCom;
