import React, { useState } from "react";
import MessageItem from "./MessageItem";
import "./messages.css"; // Import updated styles
import { useDispatch, useSelector } from "react-redux";
import SearchFilter from "./SearchFilter";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import "./Styles/MessagesList.css";
import { toast } from "react-toastify";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { setFinalConvos, setSearchTerm } from "../../../redux/slices/MsgSlice";
function MessageList({ onSelectConversation }) {
  const {
    convos,
    finalConvos,
    searchTerm,
    meetingDateSearch,
    eventMonthsearch,
    unreadSearch,
    guestCount,
    loadingNotif,
    myConnections,
    myJobOffers,
  } = useSelector((state) => state.messages);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-dot": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    // filter convos based on search term, meeting date, in searchTerm consider senderName,phone,email
    const applyFilters = () => {
      let filteredConvos = convos;
      if (searchTerm) {
        const trimmedQuery = searchTerm.trim().toLowerCase();
        filteredConvos = filteredConvos.filter((conv) => {
          const {
            senderName,
            customerName,
            providerPhone,
            customerPhone,
            senderEmail,
            customerEmail,
          } = conv;
          return (
            senderName
              ?.trim()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase()) ||
            customerName
              ?.trim()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase()) ||
            providerPhone
              ?.trim()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase()) ||
            customerPhone
              ?.trim()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase()) ||
            senderEmail
              ?.trim()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase()) ||
            customerEmail
              ?.trim()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase())
          );
        });
      }
      if (meetingDateSearch) {
        const trimmedQuery = meetingDateSearch.trim().toLowerCase();
        filteredConvos = filteredConvos.filter((conv) => {
          const { reserveDate } = conv;
          return reserveDate
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery.toLowerCase());
        });
      }
      if (eventMonthsearch) {
        filteredConvos = filteredConvos.filter((conv) => {
          const { eventDetails } = conv;
          if (!eventDetails) return false;
          try {
            const event = JSON.parse(eventDetails);
            console.log(event, "date compare");
            console.log(event[0].eventDate, "date compare");
            const eventDateMonth = new Date(event[0].eventDate)
              .toISOString()
              .slice(0, 7); // Extracts only year and month (YYYY-MM)
            console.log(eventDateMonth, "date compare");
            console.log(eventMonthsearch, "date compare");
            return eventDateMonth === eventMonthsearch.trim();
          } catch (error) {
            console.error("Invalid eventDetails format:", error);
            return false;
          }
        });
      }
      if (guestCount) {
        filteredConvos = filteredConvos.filter((conv) => {
          const { eventDetails } = conv;
          if (!eventDetails) return false;
          try {
            const event = JSON.parse(eventDetails);
            return event[0].totalGuestCount <= parseInt(guestCount);
          } catch (err) {
            console.error("Invalid eventDetails format:", err);
            return false;
          }
        });
      }

      dispatch(setFinalConvos(filteredConvos));
    };
    applyFilters();
    // add to finalConvos
  }, [convos, searchTerm, meetingDateSearch, eventMonthsearch, guestCount]);
  console.log(eventMonthsearch, "eventMonthsearch");
  const clearSearch = () => dispatch(setSearchTerm(""));

  let pendingJobs = Array.isArray(myJobOffers)
    ? myJobOffers.filter(
        (job) => job.created_by !== user.UserID && job.job_status == 0
      )
    : [];
  let pendingConnections = Array.isArray(myConnections)
    ? myConnections.filter(
        (connect) =>
          connect.created_by !== user.UserID && connect.connection_status == 0
      )
    : [];
  React.useEffect(() => {
    dispatch(setFinalConvos(convos));
  }, [convos]);

  return (
    <div className="message-list">
      <div
        className="d-flex p-1"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div>
          <p
            onClick={() => {
              navigate(-1);
            }}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "12px",
              left: "17px",
              fontSize: "18px",
              color: "#000",
              zIndex: 999,
            }}
          >
            <FaArrowLeft
              style={{
                marginRight: "6px",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            />
          </p>
        </div>
        <h2>Messages</h2>
        <div className="plus-icon">
          <i
            className="fas fa-ellipsis-h"
            style={{ cursor: "pointer" }}
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={(event) => handleClick(event)}
          >
            <FaPlus />
          </i>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                minWidth: "100px", // Adjust width as needed
                borderRadius: "10px",
              },
            }}
          >
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/weddingvendors")}
              >
                Browse Vendors
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/Localcommunityads")}
              >
                Browse Ads
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/notifications/Job-Offers")}
              >
                Job Requests
                <StyledBadge
                  badgeContent={pendingJobs?.length}
                  color="primary"
                  style={{ marginLeft: "12px" }}
                />
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
              <span
                style={{ flexGrow: 1 }}
                onClick={() => navigate("/notifications/Connection-requests")}
              >
                Connection Requests
                <StyledBadge
                  badgeContent={pendingConnections.length}
                  color="primary"
                  style={{ marginLeft: "12px" }} // Add some spacing
                />
              </span>
            </MenuItem>
            {isAdmin && (
              <MenuItem
                onClick={handleClose}
                className="wc-msg-filter-menu-item"
              >
                <span
                  style={{ flexGrow: 1 }}
                  onClick={() =>
                    navigate("/dashboardpage/notifications/Review")
                  }
                >
                  Reviews
                  <StyledBadge
                    // badgeContent={1}
                    color="primary"
                    style={{ marginLeft: "12px" }} // Add some spacing
                  />
                </span>
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <SearchFilter searchTerm={searchTerm} clearSearch={clearSearch} />
      <div className="all-messages">
        {finalConvos &&
          [...finalConvos] // Create a shallow copy of the array
            .sort((a, b) => {
              const dateA = a.updatedAt || a.createdAt; // Use updatedAt if available, otherwise fallback to createdAt
              const dateB = b.updatedAt || b.createdAt; // Use updatedAt if available, otherwise fallback to createdAt
              return new Date(dateB) - new Date(dateA); // Sort by most recent
            })
            .map((conv) => (
              <MessageItem
                key={conv.Id}
                conversation={conv}
                onSelectConversation={onSelectConversation}
              />
            ))}
      </div>
    </div>
  );
}

export default MessageList;
