/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { FaCalendar } from "react-icons/fa";
import Placeholder from "../../../assets/images/Placeholder.png";
import { updateSponsordAdStatus } from "../../../components/Header/Data";
import { useNavigate } from "react-router-dom";
import PayPalModal from "../PayPalModal";
import { toast } from "react-toastify";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners"; // Import FadeLoader
import { Col, Row } from "reactstrap";
const InActiveAds = ({ inactiveAds, fetchData, adsList, isLoading }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [prices, setPrices] = useState([]);
  const [adPrice, setAdPrice] = useState(0);
  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };

  const onEdit = (ad) => {
    console.log("Edit ad:", ad);
    navigate("/ad/sponsored/new", { state: ad });
  };
  const [paymentModal, setPaymentModal] = useState(false);
  const [adDetails, setAdDetails] = useState([]);

  const onPay = (ad, price) => {
    if (user.RoleId !== 1) {
      setPaymentModal(true);
      setAdDetails(ad);
      setAdPrice(price);
    }
  };
  const onClose = () => {
    setPaymentModal(false);
  };

  const onDelete = async (ad) => {
    console.log("Delete ad:", ad);
    const adDetails = {
      Status: "2",
      createdBy: user.UserID,
    };
    await updateSponsordAdStatus(adDetails, ad.id);
    fetchData();
    toast.success(`${ad.Header} ad Deleted`);
  };
  //status
  // status = 0 for inactive
  // status = 1 for active
  const onApprove = async (ad) => {
    const adDetails = {
      Status: "1",
      updatedBy: user.UserID,
      roleId: user.RoleId,
    };
    await updateSponsordAdStatus(adDetails, ad.id);
    fetchData();
    toast.success(`${ad.Header} Approved`);
  };

  useEffect(() => {
    const calculatePrices = () => {
      const updatedPrices = adsList.map((ad) => {
        if (ad.startAt && ad.endAt) {
          const startDate = new Date(ad.startAt);
          const endDate = new Date(ad.endAt);
          if (endDate < startDate) {
            return 0;
          }
          const durationInMilliseconds = endDate - startDate;
          const durationInMonths =
            durationInMilliseconds / (1000 * 60 * 60 * 24 * 30);
          return Math.ceil(durationInMonths) * 1;
        }
        return 0;
      });

      return updatedPrices;
    };

    const calculatedPrices = calculatePrices();
    setPrices(calculatedPrices); // Set prices to state
    console.log(calculatedPrices); // Check the updated prices
  }, [adsList]);

  return (
    <>
      {isLoading ? (
        <FadeLoader // FadeLoader component
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={isLoading}
          className="position-absolute top-50 start-50 translate-middle"
        />
      ) : (
        <div
          className={`ads mt-3 `}
          style={{ marginLeft: "9px", width: "98%" }}
        >
          {inactiveAds.length > 0 ? (
            <>
              {inactiveAds.map((ad, index) => (
                <Row
                  key={ad.id}
                  className="card each-card d-flex flex-row p-2 mb-2"
                >
                  <Col sm="3" xs="12 p-0">
                    <img
                      className="img w-100"
                      src={ad?.Photo ? ad.Photo : Placeholder}
                      alt={ad.Header}
                    />
                  </Col>
                  <Col sm="9" xs="12">
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="my-1"
                    >
                      <Col sm="6" xs="12">
                        <h6>{ad.Header}</h6>
                      </Col>
                      <Col
                        sm="6"
                        xs="12"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: 15,
                        }}
                      >
                        {user.Role === "admin" && (
                          <button
                            className="btn btn-success btn-sm"
                            onClick={() => onApprove(ad)}
                          >
                            Approve
                          </button>
                        )}
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => onEdit(ad)}
                        >
                          Edit
                          <MdEdit style={{ marginLeft: "5px" }} />
                        </button>
                        <button
                          className="Status px-2"
                          style={{
                            padding: "4px 12px",
                            marginTop: "3px",
                            borderRadius: "5px",
                            color: ad.Status === "1" ? "green" : "orange",
                          }}
                        >
                          {ad.paymentStatus}
                        </button>
                        <PayPalModal
                          isOpen={paymentModal}
                          closeModal={onClose}
                          price={adPrice}
                          adDetails={adDetails}
                        />
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleMenu(ad.id)}
                        >
                          <span style={{ fontSize: "22px" }}>⋮</span>
                          {openMenuId === ad.id && (
                            <div className="menu py-2">
                              {user.Role !== "admin" &&
                                ad.paymentStatus !== "COMPLETED" && (
                                  <div
                                    className="btn btn-success btn-sm mb-1"
                                    onClick={() => onPay(ad, prices[index])}
                                  >
                                    Pay
                                  </div>
                                )}
                              <div
                                className="btn btn-danger btn-sm mb-1 text-nowrap"
                                onClick={() => onDelete(ad)}
                              >
                                Delete <MdDelete />
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <p> {ad.description} </p>
                      <p className="text-primary">
                        <b>${prices[index]}</b>
                      </p>
                      <p>
                        <FaCalendar />
                        Active Until: {new Date(ad.endAt).toLocaleDateString()}
                      </p>
                    </div>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <>
              {" "}
              <p className="text-center p-5">No Pending Ads</p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default InActiveAds;
