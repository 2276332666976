import { Dialog, DialogTitle, IconButton, Slide } from "@mui/material";
import React from "react";
import ChatCommunication from "../../../pages/DashboardPages/inboxPages/ChatCommunication";
import { useDispatch, useSelector } from "react-redux";
import {
  setLodingConvo,
  setMessages,
  setSelectedConversation,
} from "../../../redux/slices/MsgSlice";
import { getConvoById, getParentMsg } from "../Data2";
import { setParentMsg } from "../../../redux/slices/LcaSlice";
import { toast } from "react-toastify";
import { getAllMessages } from "../Data";
import { FadeLoader } from "react-spinners";
import { IoCloseCircleOutline } from "react-icons/io5";

const ChatModal = ({ isOpen, closeModal, ad }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dispatch = useDispatch();
  const { selectedConvo, lodingConvo } = useSelector((state) => state.messages);

  const fetchParent = async () => {
    const req = {
      customerId: ad?.createdBy,
      needId: ad?.Id,
      createdBy: user?.UserID,
    };
    const response = await getAllMessages(req);
    const filterParent = response.filter((msg) => msg.parentId == 0);
    if (filterParent.length > 0) {
      dispatch(setSelectedConversation(filterParent[0]));
    } else {
      dispatch(setSelectedConversation(null));
    }
  };
  const fetchMsgs = async () => {
    dispatch(setLodingConvo(true));
    const messagesData = await getConvoById(selectedConvo?.Id);
    dispatch(setMessages(messagesData));
    dispatch(setLodingConvo(false));
  };

  React.useEffect(() => {
    if (ad) {
      fetchParent();
    }
  }, [ad]);
  React.useEffect(() => {
    if (selectedConvo) {
      fetchMsgs();
    }
  }, [selectedConvo]);
  return (
    <>
      <React.Fragment>
        <Dialog
          open={isOpen}
          keepMounted
          onClose={closeModal}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-paper": {
              width: { xs: "90%", sm: "70%", md: "50%" },
              overflow: "auto",
              maxHeight: "none",
              position: "relative",
            },
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeModal}
            aria-label="close"
            sx={{
              position: "absolute",
              padding: "6px",
              top: -4,
              right: 6,
              zIndex: 999, // Ensure it's above other elements
            }}
          >
            <IoCloseCircleOutline />
          </IconButton>
          {/* {lodingConvo ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
              className="d-flex align-items-center justify-content-center my-5 py-5"
            >
              <FadeLoader color="#36d7b7" />
            </div>
          ) : ( */}
            <ChatCommunication
              isMobileView={false}
              onBack={closeModal}
              toggleDrawer={closeModal}
              fetchMsgs={fetchMsgs}
              isChatModal={true}
              isAds={true}
            />
          {/* )} */}
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default ChatModal;
