import React from "react";
import { useSelector } from "react-redux";
import {
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";
const ImagePreviewModal = ({
  modalOpen,
  toggleModal,
  imageUrl,
  index,
  handleStatus,
}) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin";

  console.log(imageUrl, "imageUrl");

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        {" "}
        Preview {imageUrl?.image_title}
      </ModalHeader>
      <ModalBody>
        {imageUrl?.image_type == 0 ? (
          <img
            src={imageUrl.image_url}
            alt={`Image ${index}`}
            style={{
              width: "100%",
              maxHeight: "400px",
              objectFit: "contain",
              display: "block",
              margin: "auto",
            }}
          />
        ) : (
          <video
            src={imageUrl.image_url}
            controls
            style={{
              width: "100%",
              maxHeight: "400px",
              objectFit: "contain",
              display: "block",
              margin: "auto",
            }}
          />
        )}
        <ModalFooter>
          {!isAdmin ? (
            <Button
              color="danger"
              onClick={() => handleStatus(2)}
              style={{ marginTop: "10px" }}
            >
              Delete
            </Button>
          ) : isAdmin && imageUrl.image_status == 0 ? (
            <>
              <Button
                color="success"
                onClick={() => handleStatus(1)}
                style={{ marginTop: "10px" }}
              >
                Approve
              </Button>
              <Button
                color="danger"
                onClick={() => handleStatus(3)}
                style={{ marginTop: "10px" }}
              >
                Reject
              </Button>
            </>
          ) : null}
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default ImagePreviewModal;
