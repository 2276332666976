import React from "react";
import { AddImgTemplate } from "../../../../components/Header/Data3";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const TitleDialog = ({ open, handleClose, newImage, fetchImages, type }) => {
  const [title, setTitle] = React.useState("");
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const saveImage = async (event) => {
    event.preventDefault();
    if (!title) {
      toast.info("Please enter a title.");
      return;
    }
    const payload = {
      imageStatus: 0,
      imageDetails: JSON.stringify({
        ...newImage,
        imageTitle: title,
        imageDescription: "",
      }),
      imageType: type === "img" ? 0 : 1,
      userName: user?.UserName,
      createdBy: user?.UserID,
    };
    try {
      const response = await AddImgTemplate(payload);
      console.log("Images saved successfully:", response);
    } catch (error) {
      console.error("Error saving images:", error);
    }
    setTitle("");
    fetchImages();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enter Title</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter Title to Upload</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={saveImage}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TitleDialog;
