import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import PlaceHolder from "../../assets/images/Placeholder.png";
import GitHubForkRibbon from "react-github-fork-ribbon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setOppertunity } from "../../redux/slices/MsgSlice";
import { useDispatch, useSelector } from "react-redux";
import { oppertunityInbox } from "../Header/Data3";
import { fetchLCAById } from "../Header/Data";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import { FormControl, MenuItem, Select } from "@mui/material";
import { setIsMsgModalOpen, setSelectedAd } from "../../redux/slices/LcaSlice";
import { toast } from "react-toastify";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import FollowUpTasks from "../../pages/DashboardPages/inboxPages/AllDropDowns/FollowUpTasks";
import InteractionNotes from "../../pages/DashboardPages/inboxPages/AllDropDowns/InteractionNotes";

const CalenderNeedDetailModal = ({
  isOpen,
  toggle,
  selectedEvent,
  fetchAllMsg,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { oppertunity } = useSelector((state) => state.messages);
  const [oppertunityStage, setOppertunityStage] = useState(false);
  const [needDetails, setNeedDetails] = useState([]);
  const [imageIndex, setImageIndex] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventDetails = selectedEvent?.eventDetails
    ? JSON.parse(selectedEvent?.eventDetails)
    : [];
  const filteredEvents = eventDetails?.filter(
    (event) =>
      event?.eventDate ||
      event?.eventLocation ||
      event?.eventTime ||
      event?.eventType ||
      event?.totalGuestCount
  );
  console.log(selectedEvent, "selectedEvent");
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}, ${year}`;
    return formattedDate;
  }
  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }
  const openMsgModal = (event, ad) => {
    event.stopPropagation();
    dispatch(setIsMsgModalOpen(true));
    dispatch(setSelectedAd(ad));
  };
  const handleChange = (event) => {
    dispatch(setOppertunity(event.target.value));
    setOppertunityStage(true);
  };
  const addOppertunityStage = async () => {
    if (oppertunity && selectedEvent) {
      const formData = {
        messageId: selectedEvent?.Id,
        Type: oppertunity,
        createdBy:
          selectedEvent?.createdBy !== user?.UserID
            ? selectedEvent?.customerId
            : selectedEvent?.createdBy,
        receivedBy:
          selectedEvent?.createdBy === user?.UserID
            ? selectedEvent?.customerId
            : selectedEvent?.createdBy,
      };
      console.log(formData, "CalenderDashboard");
      await oppertunityInbox(formData);
      fetchAllMsg();
      setOppertunityStage(false);
    }
  };
  useEffect(() => {
    if (oppertunityStage) {
      addOppertunityStage();
    }
  }, [oppertunityStage]);
  const CardDetailsById = async () => {
    if (selectedEvent) {
      const needDetails = await fetchLCAById(selectedEvent?.postId);
      console.log(needDetails, "needDetails");
      setNeedDetails(needDetails);
    }
  };

  useEffect(() => {
    if (selectedEvent) {
      CardDetailsById();
    }
  }, [selectedEvent]);
  const handleNextImage = (event, ad) => {
    event.stopPropagation();
    const currentIndex = imageIndex[ad.Id] || 0;
    const images = JSON.parse(ad.Photo);
    const nextIndex = (currentIndex + 1) % images.length;
    setImageIndex({ ...imageIndex, [ad.Id]: nextIndex });
  };

  const handlePrevImage = (event, ad) => {
    event.stopPropagation();
    const currentIndex = imageIndex[ad.Id] || 0;
    const images = JSON.parse(ad.Photo);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setImageIndex({ ...imageIndex, [ad.Id]: prevIndex });
  };
  const handleCardClick = (selectedCard) => {
    const screenWidth = window.innerWidth;
    const isIpadPro = screenWidth === 1024; // Check for iPad Pro screen width

    // if (!isMsgModalOpen && !isOfferModalOpen) {
    if (selectedCard.engagedProfile === 1 || selectedCard.engagedCouple === 1) {
      // if (islogin) {
      if (isIpadPro) {
        navigate(`/engagedCouple/${selectedCard.createdBy}`);
      } else {
        window.open(`#/engagedCouple/${selectedCard.createdBy}`, "_blank");
      }
      // } else {
      //   // window.open("#/login", "_blank");
      //   toast.info("Please Login To View Details..");
      // }
    } else {
      // if (islogin) {
      if (isIpadPro) {
        navigate(`/requestDetails/${selectedCard.Id}`);
      } else {
        window.open(`#/requestDetails/${selectedCard.Id}`, "_blank");
      }
      // } else {
      //   //   navigate("/login");
      //   toast.info("Please Login To View Details..");
      // }
    }
    // }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Event Details</ModalHeader>
      <ModalBody className="">
        {needDetails.map((ad) => (
          <>
            <p
              className="mb-1"
              style={{ fontSize: "medium", cursor: "pointer" }}
              onClick={() => {
                islogin
                  ? navigate(`/userDetails/${ad.createdBy}`)
                  : toast.info("Please login to view profile");
                console.log(ad, "userDetails");
              }}
            >
              {ad?.FullName}
            </p>
            <Card className="shadow-sm position-realtive text-center">
              {ad?.caIdStatus === 1 ? (
                <GitHubForkRibbon color="green" position="left">
                  Verified & Trusted
                </GitHubForkRibbon>
              ) : null}
              <Row className="g-0">
                <div
                  className="position-absolute"
                  style={{
                    backgroundColor: "white",
                    width: "auto",
                    padding: "0 3px 3px 0",
                  }}
                >
                  <img
                    className=""
                    style={{
                      width: "20px",
                    }}
                    src={ad?.profilePhoto ? ad?.profilePhoto : PlaceHolder}
                    alt={ad?.firstName}
                  />
                </div>
                <Col lg="3" md="3" className="d-flex align-items-center ">
                  <div className="position-relative">
                    {ad.Photo && JSON.parse(ad.Photo).length > 1 && (
                      <button
                        className="arrow left-arrow position-absolute"
                        style={{
                          top: "50%",
                          left: "3px",
                          transform: "translateY(-50%)",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          border: "none",
                          fontSize: "30px",
                          zIndex: 1,
                        }}
                        onClick={(event) => handlePrevImage(event, ad)}
                      >
                        &#10094;
                      </button>
                    )}
                  </div>
                  <img
                    className="wc_egcpl-ad img-fluid"
                    src={
                      ad.Photo && JSON.parse(ad.Photo).length > 0
                        ? JSON.parse(ad.Photo)[imageIndex[ad.Id] || 0]
                        : PlaceHolder
                    }
                    alt={ad.Title}
                    style={{
                      objectFit: "fill",
                      height: "220px",
                      width: "220px",
                    }}
                  />
                  <div className="position-relative">
                    {ad.Photo && JSON.parse(ad.Photo).length > 1 && (
                      <button
                        className="arrow right-arrow position-absolute"
                        style={{
                          top: "50%",
                          right: "3px",
                          transform: "translateY(-50%)",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          border: "none",
                          fontSize: "30px",
                          zIndex: 1,
                        }}
                        onClick={(event) => handleNextImage(event, ad)}
                      >
                        &#10095;
                      </button>
                    )}
                  </div>
                </Col>
                <Col lg="7" md="7">
                  <CardBody
                    onClick={() => handleCardClick(ad)}
                    className="lca-card-body"
                  >
                    <CardTitle
                      tag="h5"
                      className="mb-2"
                      style={{
                        fontSize: "large",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <b>
                        {" "}
                        {ad.Title.length > 100
                          ? `${ad.Title.substring(0, 100)}...`
                          : ad.Title}
                      </b>
                    </CardTitle>
                    <CardText
                      style={{
                        fontSize: "medium",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                        marginLeft: 0,
                      }}
                    >
                      {ad.Description}
                    </CardText>
                    <div className="d-lg-flex">
                      <CardText
                        className="text-nowrap mx-0"
                        style={{ fontSize: "medium" }}
                      >
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span> {ad.Zipcode}</span> -{" "}
                        <span className=""> {ad.placeName}</span>
                      </CardText>
                      {ad.categoryName && (
                        <CardText className="" style={{ fontSize: "medium" }}>
                          <b>Service Needed:</b>{" "}
                          {ad.itemName
                            ? ad.itemName
                            : ad.subCategoryName
                            ? ad.subCategoryName
                            : ad.categoryName
                            ? ad.categoryName
                            : ""}
                        </CardText>
                      )}
                    </div>
                    <div>
                      <p className="mb-0 posting" style={{ fontSize: "small" }}>
                        Posting Date/Time:{" "}
                        {formatDate(
                          convertToUserTimezone(ad?.createdAt).split(" ")[0]
                        )}
                        &nbsp;
                        {convertTo12HourFormat(
                          convertToUserTimezone(ad?.createdAt).split(" ")[1]
                        )}
                      </p>
                    </div>
                  </CardBody>
                </Col>
                <Col
                  lg="2"
                  md="2"
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  {/* {ad.engagedCouple == 1 && (
          <div className="w-100 text-center engagedCouple">
            {isAdInMyOffers(ad) ? (
              <>
                <span className="dropdown-center">
                  <Button
                    color="success"
                    size="sm"
                    className="dropdown-toggle w-100 mb-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Call Me
                  </Button>
                  <ul
                    className="dropdown-menu dropdown-menu-center"
                    style={{ cursor: "pointer" }}
                  >
                    <li>
                      <a
                        class="dropdown-item text-center fw-bold"
                        onClick={() => handleIndiviualCall(ad)}
                      >
                        Individual Call <FaUser />
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item text-center fw-bold"
                        onClick={() => handleGroupCall(ad)}
                      >
                        Group Call <MdGroups size={20} />
                      </a>
                    </li>
                  </ul>
                </span>
                <Button
                  color="primary"
                  size="sm"
                  className="w-100 mb-2"
                  // onClick={(event) => openOfferModal(event, ad)}
                  onClick={(event) => openMsgModal(event, ad)}
                >
                  Send message
                </Button>
              </>
            ) : isAlreadyGotton(ad) ? (
              <Button
                color="primary"
                size="xs"
                className="w-100 mb-2"
                disabled
                // onClick={(event) => openOfferModal(event, ad)}
              >
                Other venues took customer
              </Button>
            ) : (
              <Button
                color="primary"
                size="sm"
                className="w-100 mb-2"
                onClick={(event) => openOfferModal(event, ad)}
              >
                Get Customer!
              </Button>
            )}
            <Button
              color={questionsVisible[ad.Id] ? "secondary" : "warning"}
              size="sm"
              className="w-100 mb-2"
              onClick={(event) => toggleQuestions(event, ad)}
            >
              {questionsVisible[ad.Id] ? "Hide Q&A" : "Show Q&A"}
            </Button>
            <Button
              color={eventsVisible[ad.Id] ? "secondary" : "success"}
              size="sm"
              className="w-100"
              onClick={(event) => toggleEvents(event, ad)}
            >
              {eventsVisible[ad.Id]
                ? "Hide Event Details"
                : "Show Event Details"}
            </Button>
          </div>
        )} */}
                  <div className="w-100 text-center need">
                    {/* <a
              href={`tel: 8002453610`}
              className="btn btn-success btn-sm mb-2 w-100"
            >
              <FaPhoneAlt /> (800)245-3610 <br />
              <span>Ext# {ad.dialExtension}</span>
            </a> */}
                    <Button
                      color="primary"
                      size="sm"
                      className="w-100"
                      onClick={(event) => openMsgModal(event, ad)}
                    >
                      Send Message
                    </Button>
                  </div>
                </Col>
              </Row>

              {/* {questionsVisible[ad.Id] && (
      <CardBody>
        <LcaCardQuestions ad={ad} />
      </CardBody>
    )}
    {eventsVisible[ad.Id] && (
      <CardBody>
        <LcaCardsEvents ad={ad} />
      </CardBody>
    )} */}
            </Card>
          </>
        ))}{" "}
        {filteredEvents?.length > 0 ? (
          <Table responsive className="table-light border text-nowrap">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Event Date</th>
                <th>Event Time</th>
                <th>Event Type</th>
                <th>Event Location</th>
                <th>Guest Count</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {moment(event.eventDate.split("T")[0])
                      .add(1, "days")
                      .format("MMM DD, YYYY")}
                  </td>
                  <td>{moment(event.eventTime, "HH:mm ").format("hh:mm A")}</td>
                  <td>{event.eventType}</td>
                  <td>{event.eventLocation}</td>
                  <td>{event.totalGuestCount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center my-3">No Events</div>
        )}
        <div style={{ width: "75%", margin: "auto" }}>
          <div className="d-flex gap-10 ">
            <FollowUpTasks />
            <InteractionNotes />
            <FormControl sx={{ minWidth: 180 }}>
              <Select
                value={oppertunity}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  fontSize: "12px",
                  backgroundColor: "lightgray",
                }}
              >
                <MenuItem value={0}>
                  <span>Opportunity Stage</span>
                </MenuItem>
                <MenuItem value={1}>Lead</MenuItem>
                <MenuItem value={2}>Invoice Sent</MenuItem>
                <MenuItem value={3}>Booked</MenuItem>
                <MenuItem value={4}>Event Completed</MenuItem>
                <MenuItem value={5}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CalenderNeedDetailModal;
