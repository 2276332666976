import Sidebar from "../SideBar";
import { Row, Col } from "reactstrap";
import "./DashboardPages.css";
import HourlyCalendar from "../../components/DashboardCalenderPages/CalenderDashboard";
import axios from "axios";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { FaDollarSign } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { FaChevronCircleRight } from "react-icons/fa";
const CalendarPage = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [calendarEvents, setCalendarEvents] = useState([]);
  const fetchEvents = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const calendarId = user.Email;
      const response = await axios.get(
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}`
      );

      setCalendarEvents(response.data.items);
    } catch (error) {
      console.error("Error fetching calendarEvents:", error);
    }
  };
  useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9}>
            <HourlyCalendar
              calendarEvents={calendarEvents}
              fetchEvents={fetchEvents}
            />
            <div className="text-center mb-3">
              <div className="d-flex align-items-center justify-content-center">
                <Row style={{ width: "90%" }}>
                  <Col lg={4} sm={12} xs={12}>
                    <h6 className="border rounded p-2 ">
                      <FaDollarSign /> - Offer Payment{" "}
                    </h6>
                    <h6 className="border rounded p-2">
                      <IoMdSettings /> - Event Details{" "}
                    </h6>
                    <h6 className="border rounded p-2">
                      <FaChevronCircleRight /> - Listing Details{" "}
                    </h6>
                  </Col>
                  <Col lg={4} sm={12} xs={12}>
                    <h6 className="border rounded p-2 bg-primary text-dark">
                      Lead
                    </h6>
                    <h6 className="border rounded p-2 bg-warning text-dark">
                      Invoice Sent
                    </h6>
                    <h6 className="border rounded p-2 bg-success text-dark">
                      Booked
                    </h6>
                  </Col>
                  <Col lg={4} sm={12} xs={12}>
                    <h6 className="border rounded p-2  bg-secondary text-dark">
                      Event Completed
                    </h6>
                    <h6 className="border rounded p-2 bg-danger text-dark">
                      Cancelled
                    </h6>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default CalendarPage;
