import React, { useEffect, useState } from "react";
import { Input, Label, FormGroup, Form } from "reactstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { MakeAnOffer, fetchBWV, getUserbyEmail } from "../Data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import "./Modal.css";
import { FaQuestionCircle } from "react-icons/fa";
import CustomerAvaliableCalander from "../CustomerAvaliableCalander";
const MakeOfferModal = ({
  isOpen,
  closeModal,
  selectedCard,
  setSchedule,
  schedule,
}) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState(null);
  const [reserveDate, setReserveDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const logUser = localStorage.getItem("USER_ROLE");
  const [isLoading, setIsLoading] = useState(false);
  const [userByEmail, setUserByemail] = useState([]);
  const [service, setService] = useState(null);
  const custTimeZone = selectedCard?.customerTimezone;
  const providerTimeZone = userByEmail[0]?.TimeZone;
  const { users } = useSelector((state) => state.socket);
  const isAdmin = user && user.Role === "admin";
  const [allServices, setAllServices] = useState([]);

  const MakeOffer = async () => {
    if (isLoading) return;
    const messageForm = {
      customerId: selectedCard?.createdBy,
      postId: selectedCard?.Id,
      postType: 3, // message 1 or requestQuote 2 or offer my service 3
      Description: message,
      messageStatus: 0,
      createdBy: isAdmin && service ? Number(service.createdBy) : user?.UserID,
      roleId: isAdmin && service ? 2 : user?.RoleId,
      ownerId: selectedCard?.createdBy,
      userName: isAdmin && service ? service.userName : user?.UserName,
      Email: isAdmin && service ? service.Email : user?.Email,
      serviceNeed: "need",
      Budget: budget,
      needId: selectedCard?.Id,
      needName: selectedCard?.Title,
      startTime: startTime,
      reserveDate: reserveDate,
      serviceTimezone: providerTimeZone,
      customerTimezone: custTimeZone,
      zoomStatus: startTime !== "" ? 1 : 0,
      // zoomRole: startTime !== "" ? user.UserID : null,
      zoomRole:
        startTime !== "" && isAdmin && service ? service.createdBy : null,
    };
    if (!budget || budget == 0) {
      toast.error("Enter valid Price");
      setIsLoading(false);
      return;
    } else if (!messageForm.Description) {
      toast.error("Enter Message");
      setIsLoading(false);
      return;
    } else if (schedule && !reserveDate) {
      toast.error("Select Reserve Date");
      setIsLoading(false);
      return;
    } else if (schedule && !startTime) {
      toast.error("Select Start Time");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    console.log(messageForm, "messageForm");
    await MakeAnOffer(messageForm);
    toast.success("Offer sent to Admin for Approval");
    closeModal();
    setBudget("");
    setMessage("");
    setReserveDate("");
    setStartTime("");
    setService(null);
    setIsLoading(false);
  };

  const handlebudget = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");
    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf("."));
    }
    if (value.length <= 6) {
      const parts = value.split(".");
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join(".");
      }
      setBudget(value);
    }
  };
  useEffect(() => {
    // Initialize budget to an empty string when component mounts
    setBudget("");
    setMessage("");
    setReserveDate("");
    setStartTime("");
  }, [selectedCard]);

  useEffect(() => {
    fecthUserByEmail();
  }, []);

  const fecthUserByEmail = async () => {
    const data = await getUserbyEmail(user?.Email);
    const bwvData = await fetchBWV();
    setAllServices(bwvData);
    setUserByemail(data);
  };

  let parsedAvailability = [];
  if (selectedCard && typeof selectedCard.availableFrom === "string") {
    try {
      parsedAvailability = JSON.parse(selectedCard.availableFrom);
    } catch (error) {
      console.error("Error parsing selectedCard.availableFrom:", error);
      parsedAvailability = [];
    }
  }

  const convertTo12Hour = (time) => {
    if (typeof time !== "string") {
      return "";
    }
    const [hours, minutes] = time.split(":");
    const suffix = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${suffix}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={() => {
        closeModal(); // Close the modal
        // Reset the state variables for budget, message, reserveDate, startTime, and endTime
        setBudget("");
        setMessage("");
        setReserveDate("");
        setStartTime("");
        //setSchedule(false); // Optionally reset the schedule state variable if needed
      }}
      className="hire-modal modal-content"
      size="lg"
    >
      <div
        className="d-flex justify-content-end "
        style={{ margin: "20px 32px 0 0" }}
      >
        <button
          type="button"
          className="btn-close"
          onClick={closeModal}
        ></button>
      </div>
      <ModalBody className="px-5 py-3">
        <div className="d-flex flex-row align-items-center pb-3">
          <div style={{ width: "50px", height: "50px", marginRight: "30px" }}>
            <img
              src={
                selectedCard && selectedCard?.profilePhoto
                  ? selectedCard?.profilePhoto
                  : PlaceHolder
              }
              style={{ height: "100%", width: "100%", borderRadius: "50%" }}
              alt=""
            />
          </div>
          <div>
            <h5>{selectedCard ? selectedCard.FullName : ""}</h5>
          </div>
        </div>
        {(logUser && logUser === "provider") || logUser === "admin" ? (
          <div>
            {" "}
            <Form>
              {logUser === "admin" && (
                <FormGroup>
                  <Label for="provider">Providers</Label>
                  <Input
                    type="select"
                    name="provider"
                    id="provider"
                    onChange={(e) => setService(JSON.parse(e.target.value))}
                  >
                    <option value="">Select Service Provider</option>
                    {allServices?.map((list) => (
                      <option key={list.Id} value={JSON.stringify(list)}>
                        {list.listName} ({list.FullName && list.FullName})
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
              <FormGroup>
                <Label>
                  Package Price <b className="text-danger">*</b>
                </Label>
                <Input
                  type="text"
                  name="Budget"
                  // value={budget ? `$${budget}` : null}
                  placeholder="$00.00"
                  value={budget === "" ? "" : `$${budget}`}
                  onChange={(e) => handlebudget(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Make a Warm Introduction <b className="text-danger">*</b>{" "}
                  <FaQuestionCircle
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Please list all services included for the price otherwise the offer may not be approved and sent to the customer"
                  />
                </Label>
                <Input
                  type="textarea"
                  //placeholder="Details of the items included in the package.."
                  onChange={(e) => setMessage(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="checkbox"
                  onChange={() => {
                    setSchedule(!schedule);
                    setReserveDate("");
                    setStartTime("");
                  }}
                />{" "}
                <Label>Schedule Zoom Call</Label>
              </FormGroup>
            </Form>
            {schedule && (
              <Form>
                <span>
                  <small>
                    <b>
                      {" "}
                      Please schedule the call using the tool below within the
                      Customer's mentioned availability schedule.
                    </b>
                  </small>
                </span>
                <p className="py-2">
                  <b className="text-black">Note: </b> All the scheduled calls
                  are set to the default PST timezone. If necessary, please go
                  to the Profile section to change the timezone
                </p>
                {parsedAvailability.length > 0 && (
                  <CustomerAvaliableCalander
                    reserveDate={reserveDate}
                    startTime={startTime}
                    setReserveDate={setReserveDate}
                    setStartTime={setStartTime}
                    availableDays={parsedAvailability}
                    ownerId={selectedCard.createdBy}
                  />
                )}
                {startTime && (
                  <p>
                    <small>
                      The Calls will be scheduled for 30 min starting from{" "}
                      {convertTo12Hour(startTime)}
                    </small>
                  </p>
                )}
              </Form>
            )}
            <ModalFooter className="px-0" style={{ border: "none" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  MakeOffer(selectedCard.Id);
                }}
                disabled={isLoading}
              >
                Get Customer!
              </button>
            </ModalFooter>
            {/* </div> */}
          </div>
        ) : (
          <div className="text-center">
            <h5 className="text-center mt-3 mb-5">
              <p className="text-center fs-6 fw-bold lead">
                To make an offer, please first list your service.
              </p>{" "}
              <br />
              <p style={{ fontWeight: "bold", fontSize: "18px", color: "red" }}>
                Go to Dashboard &gt; Services for Sale &gt; Add New
              </p>{" "}
              <br />
              <p className="text-center fs-6 fw-bold lead">
                This will allow the buyer to see who is making the offer.
              </p>
            </h5>
            <div class="d-flex justify-content-end">
              <button
                className="btn btn-primary px-4"
                style={{ marginRight: "20px" }}
                onClick={() => navigate("/create-service")}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default MakeOfferModal;
