import React from "react";
import { Button, Label } from "reactstrap";
import Switch from "@mui/material/Switch";
import { FcClearFilters } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchLocation,
  setSearchTerm,
  setSelectedPeopleCategory,
  setUserMaps,
} from "../../redux/slices/PeopleInComSlice";

const MoreFilters = () => {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const dispatch = useDispatch();
  const { userMaps } = useSelector((state) => state.peopleInCom);
  const handleChange = (event) => {
    dispatch(setUserMaps(event.target.checked));
  };

  const clearFilters = () => {
    dispatch(setSearchLocation(""));
    dispatch(setSearchTerm(""));
    dispatch(setSelectedPeopleCategory(null));
  };

  return (
    <div>
      <Label>More Results</Label>
      <div className="d-flex justify-content-between">
        <button
          className="btn"
          style={{ backgroundColor: "#f1f1f1" }}
          onClick={() => clearFilters()}
        >
          Clear Filters <FcClearFilters style={{ fontSize: "1rem" }} />
        </button>
        <div>
          Show Map :
          <Switch
            onChange={(event) => handleChange(event)}
            {...label}
            checked={userMaps}
          />
        </div>
      </div>
    </div>
  );
};

export default MoreFilters;
