import React from "react";
import { Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPeopleCategory } from "../../redux/slices/PeopleInComSlice";

const SearchByType = () => {
  const dispatch = useDispatch();
  const { categories, selectedPeopleCategory } = useSelector(
    (state) => state.peopleInCom
  );

  const handleClearInput = () => {
    dispatch(setSelectedPeopleCategory(null));
  };

  const handleCategoryChange = (e) => {
    const selectedId = e.target.value;
    const selectedCategory = categories.find((cat) => cat.Id == selectedId); // Ensure type match
    dispatch(setSelectedPeopleCategory(selectedCategory || null));
  };

  console.log(selectedPeopleCategory, "selectedPeopleCategory");
  return (
    <div>
      <Label>Search By Type</Label>
      <div className="input-container">
        <Input
          type="select"
          name="subCategory"
          id="subCategory"
          value={selectedPeopleCategory?.Id || ""}
          disabled={categories?.length === 0}
          onChange={handleCategoryChange}
        >
          <option value="">
            {categories?.length === 0 ? "" : "Select a Category"}
          </option>
          {categories?.map((cat) => (
            <option key={cat.Id} value={cat.Id}>
              {cat.Name}
            </option>
          ))}
        </Input>

        {selectedPeopleCategory && (
          <span className="clear-button" onClick={handleClearInput}>
            &#x2715;
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchByType;
