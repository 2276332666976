// BwvSideBar.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import Slider from "rc-slider";
import BwvOptions from "./BwvOptions";
import { fetchOptions, fetchOptionsList } from "../Data";
import PlacesSearch from "../../PlacesSearch";
import PlaceSearch from "../../PlacesSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  setFinalBwv,
  setFinalNeeds,
  setSelectedCat,
} from "../../../redux/slices/BwvData";
const BwvSideBar = ({
  categories,
  subCategories,
  items,
  selectCat,
  isReset,
}) => {
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [minPriceInput, setMinPriceInput] = useState(0);
  const [maxPriceInput, setMaxPriceInput] = useState(10000);
  const [radius, setRadius] = useState([1, 100]);
  const [selectedSubCat, setSelectedSubCat] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const {
    selectedOption,
    selectedOptionData,
    originalNeeds,
    originalBwv,
    searchTerm,
    finalBwv,
    finalNeeds,
    selectedCat,
    bwvSearchQuery,
  } = useSelector((state) => state.bwvData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchOptionsData = async (id) => {
    const listData = await fetchOptionsList(id);
    setListOptions(listData);
    const options = {};
    for (const option of listData) {
      try {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      } catch (error) {
        console.error("Error fetching options:", error.message);
      }
    }
    setOptionsData(options);
  };

  useEffect(() => {
    if (selectCat) {
      handleCategoryClick(selectCat.cat);
    }
    dispatch(setSelectedCat(selectCat));
    if (isReset) {
      dispatch(setSelectedCat(null));
      setSelectedSubCat(null);
      setSelectedItem(null);
      setListOptions(null);
      setOptionsData(null);
    }
  }, [isReset]);

  const handleCategoryClick = (category) => {
    dispatch(setSelectedCat(category == selectCat ? null : category));
    if (category.Id) {
      fetchOptionsData(category.Id);
    }
    if (category.Id === 1) {
      navigate("/Localcommunityads");
    }
  };
  const handleItemClick = (item, ev) => {
    setSelectedItem((prev) => (prev === item ? null : item));

    ev.stopPropagation();
  };
  const handleSubCatClick = (subCat, ev) => {
    setSelectedSubCat((prevSubCat) => (prevSubCat === subCat ? null : subCat));

    ev.stopPropagation();
  };

  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }
  const handlePriceChange = (value) => {
    setPriceRange(value);
    setMinPriceInput(value[0]);
    setMaxPriceInput(value[1]);
  };
  useEffect(() => {
    let products = originalBwv;
    let needs = originalNeeds;

    const apply = () => {
      if (selectedCat) {
        products = products.filter(
          (product) => selectedCat.Id === product.categoryId
        );
        needs = needs.filter((need) => selectedCat.Id === need.categoryId);
      }
      if (selectedSubCat) {
        products = products.filter(
          (product) => selectedSubCat.Id === product.subCategoryId
        );
        needs = needs.filter(
          (need) => selectedSubCat.Id === need.subCategoryId
        );
      }
      if (selectedItem) {
        products = products.filter((product) => {
          const subCatItemName =
            selectedItem.subCatItemName?.trim().toLowerCase() || "";
          const itemName = product.itemName?.trim().toLowerCase() || "";
          const subCategoryName =
            product.subCategoryName?.trim().toLowerCase() || "";
          const categoryName = product.categoryName?.trim().toLowerCase() || "";
          return (
            subCatItemName === itemName ||
            subCatItemName === subCategoryName ||
            subCatItemName === categoryName
          );
        });
        needs = needs.filter((need) => {
          const subCatItemName =
            selectedItem.subCatItemName?.trim().toLowerCase() || "";
          const itemName = need.itemName?.trim().toLowerCase() || "";
          const subCategoryName =
            need.subCategoryName?.trim().toLowerCase() || "";
          const categoryName = need.categoryName?.trim().toLowerCase() || "";
          return (
            subCatItemName === itemName ||
            subCatItemName === subCategoryName ||
            subCatItemName === categoryName
          );
        });
      }
      if (searchTerm) {
        products = products.filter(
          (product) =>
            product.Location &&
            product.Location.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      console.log(needs, "bwvSearchQuery-needs");
      if (bwvSearchQuery) {
        const trimmedQuery = bwvSearchQuery.trim().toLowerCase();
        products = products.filter((Product) => {
          const listNameMatch = Product.listName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const subCategoryMatch = Product.subCategoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const catMatch = Product.categoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const itemMatch = Product.itemName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const location = Product.Location?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const decMatch = Product.Description?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          return (
            listNameMatch ||
            subCategoryMatch ||
            catMatch ||
            itemMatch ||
            location ||
            decMatch
          );
        });
        needs = needs.filter((need) => {
          const title = need.Title?.trim().toLowerCase().includes(trimmedQuery);
          const subCategoryMatch = need.subCategoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const catMatch = need.categoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const itemMatch = need.itemName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const decMatch = need.Description?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          return title || subCategoryMatch || catMatch || itemMatch || decMatch;
        });
      }
      console.log(products, "products-before-price-filter");
      if (priceRange && priceRange.length === 2) {
        const [minRange, maxRange] = priceRange;
        products = products.filter((product) => {
          const minPackagePrice = getMinPackagePrice(
            JSON.parse(product.Package)
          );
          const price = Number(product.hourlyPrice) || minPackagePrice; // Ensure it's a number
          return price >= minRange && price <= maxRange;
        });
      }
      console.log(products, "products-after-price-filter");

      if (radius) {
        products = products.filter((product) => product.Radius < radius[1]);
      }
      console.log(products, "products-after-radius-filter");
      if (selectedOption) {
        products = products.filter((product) =>
          product.customDetails.hasOwnProperty(selectedOption.list_name)
        );
      }
      if (selectedOptionData) {
        const selectedOptionName = selectedOptionData.option_name;
        products = products.filter((product) => {
          // Check if customDetails has any array that contains the selected option name
          return Object.values(product.customDetails).some((optionsArray) =>
            optionsArray.some(
              (option) => option.option_name === selectedOptionName
            )
          );
        });
      }
    };
    apply();
    dispatch(setFinalBwv(products));
    dispatch(setFinalNeeds(needs));
    console.log(
      selectedCat,
      selectedSubCat,
      selectedItem,
      searchTerm,
      bwvSearchQuery,
      priceRange,
      radius,
      selectedOption,
      selectedOptionData,
      "products-Filter-Data"
    );
    console.log(products, "Filter-Data-products");
  }, [
    selectedCat,
    selectedSubCat,
    selectedItem,
    searchTerm,
    originalBwv,
    originalNeeds,
    bwvSearchQuery,
    priceRange,
    radius,
    selectedOption,
    selectedOptionData,
  ]);

  const handleMinPriceInputChange = (event) => {
    const minValue = parseInt(event.target.value, 10);
    setMinPriceInput(minValue);
    setPriceRange([minValue, priceRange[1]]);
  };
  const handleMaxPriceInputChange = (event) => {
    const maxValue = parseInt(event.target.value, 10);
    setMaxPriceInput(maxValue);
    setPriceRange([priceRange[0], maxValue]);
  };
  const filterByLocation = () => {
    if (!searchTerm) {
      dispatch(setFinalBwv(originalBwv));
      return;
    }
    tgl_filter_close();
  };
  const tgl_filter_close = () => {
    var myele = document.getElementById("bwv-sidebar");
    myele.classList.remove("bwvfilter-show");
    myele.classList.add("bwvfilter-close");
  };
  const handleRadius = (value) => {
    setRadius([1, value[1]]); // Update the state to store only the maximum value
  };
  const initialCategoriesToShow = 7;
  const [visibleCategories, setVisibleCategories] = useState(
    categories.slice(0, initialCategoriesToShow)
  );
  console.log(finalNeeds, "bwvSearchQuery-finalNeeds");
  useEffect(() => {
    setVisibleCategories(categories.slice(0, initialCategoriesToShow));
  }, [categories, initialCategoriesToShow]);
  const handleToggleShowAll = () => {
    if (showAll) {
      setVisibleCategories(categories.slice(0, initialCategoriesToShow));
    } else {
      setVisibleCategories(categories);
    }
    setShowAll(!showAll);
  };
  return (
    <div className="sidebar-for">
      <div className="categoryList">
        <span
          onClick={tgl_filter_close}
          className="tglbwv-filter-close text-end"
          id="tglbwv-filter-close"
        >
          X
        </span>
        <h5 className="pb-3">Category</h5>
        <ul
          style={{
            marginBottom: "10px",
            backgroundColor: "#ffffff",
            color: "#000000",
            borderRight: "1px solid rgb(224, 224, 224)",
            padding: "5px 5px 0px 0px",
            height: "100%",
          }}
          className="sidebar"
        >
          {visibleCategories.map((category) => (
            <li
              key={category.Id}
              onClick={() => handleCategoryClick(category)}
              className={
                selectedCat && selectedCat.Id === category.Id ? "selected" : ""
              }
              style={{
                cursor: "pointer",
                listStyleType: "none",
                borderBottom: "1px solid rgb(224, 224, 224)",
                borderRadius: "0px 25px 0px 25px",
                padding: "15px",
                fontWeight: "bold",
              }}
            >
              {category.Name}
              {selectedCat && selectedCat.Id === category.Id && (
                <div className="items-list">
                  <ul>
                    {subCategories
                      .filter((subCat) => subCat.CategoryId === category.Id)
                      .map((subCat) => (
                        <li
                          key={subCat.id}
                          // eslint-disable-next-line no-restricted-globals
                          onClick={(event) => handleSubCatClick(subCat, event)}
                          className={
                            selectedSubCat === subCat ? "selected-item" : ""
                          }
                          style={{ listStyleType: "none" }}
                        >
                          {subCat.Name}
                          {selectedSubCat === subCat && (
                            <div className="subCat-items-list">
                              <ul>
                                {items
                                  .filter((item) => item.subCatId === subCat.id)
                                  .map((item) => (
                                    <li
                                      key={item.subCatItemId}
                                      // eslint-disable-next-line no-restricted-globals
                                      onClick={(event) =>
                                        handleItemClick(item, event)
                                      }
                                      className={
                                        selectedItem === item ? "selected" : ""
                                      }
                                      style={{
                                        marginLeft: "20px",
                                      }}
                                    >
                                      {item.subCatItemName}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div
          className="show-all-text"
          style={{ color: "blue", textAlign: "right", cursor: "pointer" }}
          onClick={handleToggleShowAll}
        >
          {showAll && categories.length > initialCategoriesToShow
            ? "Show Less"
            : categories.length === initialCategoriesToShow
            ? null
            : "Show All"}
        </div>
      </div>
      <div className="bwvoptions">
        <BwvOptions
          listOptions={listOptions}
          optionsData={optionsData}
          isReset={isReset}
        />
      </div>
      <div className="price-slider">
        <h6 style={{ marginBottom: "10px" }}>Price:</h6>
        <Slider
          min={1}
          max={10000}
          range
          value={priceRange}
          onChange={handlePriceChange}
          trackStyle={[{ backgroundColor: "#007bff" }]}
          handleStyle={[
            { borderColor: "#007bff", backgroundColor: "#007bff" },
            { borderColor: "#007bff", backgroundColor: "#007bff" },
          ]}
          railStyle={{ backgroundColor: "#c5c5c5" }}
          step={1}
        />
        <p style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              color:
                priceRange[0] < 0 || priceRange[0] > 10000 ? "red" : "black",
            }}
          >
            ${isNaN(priceRange[0]) ? 0 : priceRange[0]}
          </span>
          <span
            style={{
              color:
                priceRange[1] < 0 || priceRange[1] > 10000 ? "red" : "black",
            }}
          >
            ${isNaN(priceRange[1]) ? 0 : priceRange[1]}
          </span>
        </p>
        <p>set up manually</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Input
            style={{ width: "90px" }}
            type="number"
            value={minPriceInput}
            onChange={handleMinPriceInputChange}
          />
          <Input
            style={{ width: "90px" }}
            type="number"
            value={maxPriceInput}
            onChange={handleMaxPriceInputChange}
          />
        </div>
      </div>

      <div className="location-filter">
        <h6 style={{ marginBottom: "10px" }}>Location:</h6>
        <Row>
          <Col xs={8} md={12}>
            <PlaceSearch searchTerm={searchTerm} />
          </Col>
          <Col xs={4} md={12}>
            <button
              className="btn btn-primary w-100 mobile-only m-0"
              onClick={filterByLocation}
            >
              Search
            </button>
          </Col>
        </Row>
      </div>

      {searchTerm && (
        <div className="radius-slider">
          <h6 style={{ marginBottom: "10px" }}>Radius:</h6>
          <Slider
            min={1}
            max={100}
            range
            value={radius} // Pass the entire radius array
            onChange={(value) => handleRadius(value)}
            trackStyle={[{ backgroundColor: "#007bff" }]}
            handleStyle={[
              { borderColor: "#007bff", backgroundColor: "#007bff" },
              { borderColor: "#007bff", backgroundColor: "#007bff" },
            ]}
            railStyle={{ backgroundColor: "#c5c5c5" }}
            step={1}
          />

          <p>{radius[1]} miles</p>
        </div>
      )}
      <button
        className=" btn btn-sm btn-outline-primary w-100 mb-1 "
        onClick={() => {
          navigate("/create-service");
        }}
      >
        List your Business
      </button>
    </div>
  );
};

export default BwvSideBar;
